import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from '../styles/components/AppHeader.module.css'

interface IStyledLinkP {
  label: string
  // eslint-disable-next-line react/require-default-props
  // icon?: JSX.Element
  onClick: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void | null | Promise<boolean | undefined>
}

function StyledLink({ label, onClick }: IStyledLinkP) {
  return (
    <button
      type="button"
      data-log={`appHeader.button.1.${label}`}
      onClick={onClick}
      className={styles['link-wrapper']}
    >
      {label}
    </button>
  )
}

export type AppHeaderProps = {
  links?: {
    label?: string
    icon?: JSX.Element
    onClick: (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void | null | Promise<boolean | undefined>
  }[]
  title: string
}

export default function AppHeader({ links, title }: AppHeaderProps) {
  const isIOS = useMemo(() => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    // @ts-ignore
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
  }, [])

  const { ref: emptyElement, inView } = useInView({ rootMargin: isIOS ? '-140px' : '-120px' })

  const [classIsReady, setClass] = useState(false)
  const [transitionClassIsReady, setTransitionClass] = useState(false)

  useEffect(() => {
    if (inView) {
      setClass(false)
    }
    if (!inView) {
      setTransitionClass(true)
      setClass(true)
    }
  }, [inView])

  const headerLinks = links?.map((link) => (
    <StyledLink key={link.label} label={link.label || ''} onClick={(e) => link.onClick(e)} />
  ))

  useLayoutEffect(() => {
    document.documentElement.style.setProperty(
      '--selected-scrollbar-margin-top',
      `${headerLinks?.length ? '9.3rem' : '5.5rem'}`,
    )
    document.documentElement.style.setProperty('--selected-scrollbar-margin-bottom', '5.5rem')
  }, [])

  return (
    <div className={styles[`${headerLinks?.length ? '' : 'next-sibling-less-margin'}`]}>
      <div
        className={[
          styles['header-grid'],
          (isIOS && styles.ios) || '',
          (classIsReady && links?.length && styles.shrink) || '',
          (transitionClassIsReady && links?.length && styles['transition-effect']) || '',
        ].join(' ')}
      >
        <div>{headerLinks}</div>
        <h1
          className={[
            (classIsReady && links?.length && styles['reformat-headline']) || '',
            (transitionClassIsReady && links?.length && styles['transition-effect']) || '',
          ].join(' ')}
        >
          {title}
        </h1>
      </div>
      <div
        className={(isIOS && styles['ios-empty-object']) || styles['empty-object']}
        ref={emptyElement}
      />
    </div>
  )
}

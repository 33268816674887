import React, { useEffect, useState } from 'react'
import { useMountedRef, RippleButton } from '@uniqs-labs/ui-react-lib'
import { useRouter } from 'next/router'
import { IconLayoutGridAdd } from '@tabler/icons'
import { fetchGetJSON, fetchPostJSON } from '../utils/api-helpers'
import getStripe from '../utils/get-stripejs'
import { PriceListInformationTypes } from '../pages/api/stripe/get-prices'
import PriceList from './PriceList'
import styles from '../styles/components/CheckoutForm.module.css'
import pricesInfo from '../prices/prices'
import currency from '../prices/currency'
import intToFloat from '../prices/intToFloat'

interface ICheckoutFormP {
  selectedTeamId: string
  priceId: string
}

function CheckoutForm({ selectedTeamId, priceId }: ICheckoutFormP) {
  const router = useRouter()
  const mounted = useMountedRef()
  const [, setLoading] = useState(false)
  const [prices, setPrices]: [
    PriceListInformationTypes | [],
    React.Dispatch<React.SetStateAction<any>>,
  ] = useState([])
  const [firstTimePayment, setFirstTimePayment] = useState(true)
  const currentPrice = prices?.find((price) => price.id === priceId)

  const handleChangePlan = (newPriceId: string) => {
    setLoading(true)
    let response: any
    fetchPostJSON('/api/stripe/create-checkout-session', {
      priceId: newPriceId,
    })
      .then((res) => {
        response = res
        return getStripe()
      })
      .then((stripe) =>
        stripe!.redirectToCheckout({
          sessionId: response.id,
        }),
      )
      .catch(() => {
        // console.warn(error.message)
      })
      .finally(() => setLoading(false))
  }

  const handleManageAccount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setLoading(true)
    fetchPostJSON('/api/stripe/create-portal-session', {
      teamId: selectedTeamId,
    })
      .then((res) => {
        router.push(res.url)
      })
      .catch(() => {
        // console.warn(error.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    // should also be included in a react provider for offline use
    fetchGetJSON('/api/stripe/get-prices').then((response: PriceListInformationTypes) => {
      if (!mounted.current || !response) return
      setPrices(response)
    })
  }, [])

  useEffect(() => {
    if (priceId === 'free') return
    setFirstTimePayment(false)
  }, [priceId])

  return (
    (prices.length && firstTimePayment && (
      <PriceList
        prices={prices}
        desiredPriceKey={undefined}
        onSelect={handleChangePlan}
        currentPriceId={priceId}
        smallVersion
        enhancedText={undefined}
      />
    )) ||
    (!firstTimePayment && currentPrice && (
      <>
        <div
          className={styles.innergrid}
          style={{
            border: `3px solid ${pricesInfo[currentPrice.metadata?.key].color}`,
          }}
        >
          <h4>{currentPrice.name}</h4>
          {(currentPrice.metadata.key !== 'organization' && (
            <>
              <h1 style={{ color: pricesInfo[currentPrice.metadata?.key].color }}>{`${
                intToFloat(currentPrice.unit_amount || 0, 2) / 100
              }${currency[currentPrice.currency]}`}</h1>{' '}
              <p> /month</p>
            </>
          )) || (
            <>
              <IconLayoutGridAdd size={50} color={pricesInfo[currentPrice.metadata?.key].color} />
              <div />
            </>
          )}
          <h6>{pricesInfo[currentPrice.metadata?.key].description}</h6>
        </div>

        <RippleButton
          primary
          modalOptimized
          size="small"
          label="Manage plan"
          onClick={handleManageAccount}
        />
      </>
    )) || <div />
  )
}

export default CheckoutForm

const priceInformation = {
  free: {
    name: 'Free',
    description: 'Try out Mail Builder',
    recommended: false,
    color: 'var(--selected-font-grey-color)',
    features: [
      {
        name: 'Windows, IOS* and Android App',
      },
      {
        name: 'Up to 1 mail template',
      },
      {
        name: 'Up to 1 member',
      },
    ],
  },
  small: {
    name: 'Starter package',
    description: 'Up to 10 mail templates',
    recommended: true,
    color: 'var(--selected-action-color-2)',
    features: [
      {
        name: 'Windows, IOS* and Android App',
      },
      {
        name: 'Up to 10 mail templates',
      },
      {
        name: 'Up to 3 members',
      },
    ],
  },
  standard: {
    name: 'Standard package',
    description: 'Up to 50 mail templates',
    recommended: true,
    color: 'var(--selected-action-color)',
    features: [
      {
        name: 'Windows, IOS* and Android App',
      },
      {
        name: 'Up to 50 mail templates',
      },

      {
        name: 'Up to 5 members',
      },
    ],
  },
  large: {
    name: 'Advanced package',
    description: 'Up to 100 mail templates',
    recommended: true,
    color: 'var(--selected-action-color-3)',
    features: [
      {
        name: 'Windows, IOS* and Android App',
      },
      {
        name: 'Up to 100 mail templates',
      },

      {
        name: 'Up to 15 members',
      },
    ],
  },
  organization: {
    name: 'Organization package',
    description: 'Organization level features',
    recommended: true,
    color: 'var(--selected-action-color-4)',
    features: [
      {
        name: 'Windows, IOS* and Android App',
      },
      {
        name: 'Unlimited mail templates',
      },
      {
        name: 'Automation solutions',
      },
      {
        name: 'Unlimited members',
      },
      {
        name: 'Organization design',
      },
      {
        name: 'Customized organization elements',
      },
    ],
  },
}

export default priceInformation

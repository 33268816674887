import React, { useEffect, useReducer, useState } from 'react'
import {
  Carousel,
  RippleButton,
  Modal,
  Formrick,
  Notification,
  useMountedRef,
} from '@uniqs-labs/ui-react-lib'
import { IconLayoutGridAdd } from '@tabler/icons'
import { PriceListInformationTypes } from '../pages/api/stripe/get-prices'
import styles from '../styles/components/PriceList.module.css'
import pricesInfo from '../prices/prices'
import currency from '../prices/currency'
import intToFloat from '../prices/intToFloat'
import 'react-phone-input-2/lib/plain.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/grid'
import '@uniqs-labs/ui-react-lib/carousel'
import { fetchPostJSON } from '../utils/api-helpers'
import { ContactOffice } from '../pages/api/contact/contact'

interface IPriceListP {
  // prices comes from api
  prices: PriceListInformationTypes
  onSelect: (newPriceId: string) => void
  currentPriceId: string | undefined
  desiredPriceKey: string | undefined
  enhancedText: string | undefined
  smallVersion: boolean
}

const initialRequestForm = [
  {
    id: 'firstName',
    title: 'First name',
    type: 'text' as 'text',
    value: null,
    required: true,
  },
  {
    id: 'lastName',
    title: 'Last name',
    type: 'text' as 'text',
    value: null,
    required: true,
  },
  {
    id: 'workEmail',
    title: 'Work email',
    type: 'email' as 'email',
    value: null,
    required: true,
  },
  {
    id: 'jobTitle',
    title: 'Job title',
    type: 'text' as 'text',
    value: null,
    required: false,
  },
  {
    id: 'phoneNumber',
    title: 'Phone number',
    type: 'phoneNumber' as 'phoneNumber',
    value: null,
    required: true,
  },
  {
    id: 'companyName',
    title: 'Company name',
    type: 'text' as 'text',
    value: null,
    required: true,
  },
  {
    id: 'companySize',
    title: 'Company size',
    type: 'selection' as 'selection',
    value: '1-19',
    required: true,
    options: ['1-19', '20-49', '50-99', '100-250', '251-1500', '1500+'],
  },
  {
    id: 'requestBody',
    title: 'How can our team help you?',
    type: 'textArea' as 'textArea',
    value: null,
    required: true,
  },
]

// from metatag priceInfo

function PriceList({
  prices,
  onSelect,
  currentPriceId = undefined,
  desiredPriceKey,
  smallVersion,
  enhancedText = 'Most used plan',
}: IPriceListP) {
  const [contactUsVisible, toggleContactUsModalVisible] = useReducer((visible) => !visible, false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showNotification, setShowNotification] = useState({ state: false, type: '', message: '' })
  const mounted = useMountedRef()

  const onSendFormRequest = async (
    requestForm: typeof initialRequestForm,
    captchaCode?: string,
  ) => {
    const firstName = requestForm.find((input) => input.id === 'firstName')?.value as string
    const lastName = requestForm.find((input) => input.id === 'lastName')?.value as string
    const email = requestForm.find((input) => input.id === 'email')?.value as string
    const phoneNumber = requestForm.find((input) => input.id === 'phoneNumber')?.value as string
    const jobTitle = requestForm.find((input) => input.id === 'jobTitle')?.value as string
    const companyName = requestForm.find((input) => input.id === 'companyName')?.value as string
    const companySize = requestForm.find((input) => input.id === 'companySize')?.value as string
    const requestBody = requestForm.find((input) => input.id === 'requestBody')?.value as string
    setSubmitLoading(true)
    try {
      await fetchPostJSON('/api/contact/contact', {
        execute: 'contactOffice',
        subject: 'Organization Price contact form',
        captcha: captchaCode,
        firstName,
        lastName,
        message: requestBody,
        email,
        jobTitle,
        phoneNumber,
        companyName,
        companySize,
      } as ContactOffice['body'])
      setShowNotification({ state: true, type: 'info', message: 'Successfully send request' })
      setSubmitLoading(false)
    } catch (e) {
      setShowNotification({ state: true, type: 'error', message: 'Something went wrong' })
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    if (!showNotification.state) return
    setTimeout(() => {
      if (!mounted.current) return
      setShowNotification({ state: false, type: '', message: '' })
    }, 3000)
  }, [showNotification])

  return (
    <div className="pricelist-grid">
      <Carousel
        pagination
        navigation
        loop={false}
        autoplay={{ delay: 4000, disableOnInteraction: true }}
        slidesPerView={{
          start: 2,
          s: 3,
          m: (smallVersion && 3) || 4,
          l: (smallVersion && 3) || 5,
        }}
        spaceBetween={{
          start: 6,
          s: 8,
          m: (smallVersion && 8) || 10,
          l: (smallVersion && 8) || 15,
        }}
        slides={prices
          .sort((priceA, priceB) => priceA.unit_amount! - priceB.unit_amount!)
          .map((price) => ({
            content: (
              <div key={price.id} className={styles.outergrid}>
                {price.metadata.key === desiredPriceKey ? (
                  <h6
                    className={styles.favorable}
                    style={{ backgroundColor: pricesInfo[price.metadata?.key].color }}
                  >
                    {enhancedText}
                  </h6>
                ) : (
                  <div />
                )}
                <div
                  className={styles.innergrid}
                  style={{
                    border: `${
                      (price.metadata.key === desiredPriceKey &&
                        `3px solid ${pricesInfo[price.metadata?.key].color}`) ||
                      '1px solid var(--selected-shadow-color)'
                    }`,
                  }}
                >
                  <div style={{ backgroundColor: pricesInfo[price.metadata?.key].color }} />
                  <h5>{price.name}</h5>
                  {(price.metadata.key !== 'organization' && (
                    <>
                      <h1 style={{ color: pricesInfo[price.metadata?.key].color }}>{`${
                        intToFloat(price.unit_amount || 0, 2) / 100
                      }${currency[price.currency]}`}</h1>{' '}
                      <p> /month</p>
                    </>
                  )) || (
                    <>
                      <IconLayoutGridAdd size={50} color={pricesInfo[price.metadata?.key].color} />
                      <div />
                    </>
                  )}
                  {(price.id !== currentPriceId && (
                    <RippleButton
                      key={price.id}
                      size="small"
                      primary={price.metadata.key !== 'organization'}
                      id={price.id}
                      label={(() => {
                        switch (price.metadata.key) {
                          case 'organization':
                            return 'Contact us'
                          default:
                            return 'Select'
                        }
                      })()}
                      onClick={(e: any) => {
                        e.preventDefault()
                        if (price.metadata.key === 'organization')
                          return toggleContactUsModalVisible()
                        return onSelect(price.id)
                      }}
                    />
                  )) || <h5>Current plan</h5>}
                  <h5>{pricesInfo[price.metadata?.key].description}</h5>

                  <ul className={styles.features}>
                    {pricesInfo[price.metadata?.key].features.map((feature) => (
                      <li key={feature.name}>{feature.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ),
          }))}
      />

      <div className={styles.note}>
        <p>(*) Coming soon.</p>
      </div>
      {contactUsVisible && (
        <Modal onClose={() => toggleContactUsModalVisible()} className={styles.scrollbar}>
          <Formrick<typeof initialRequestForm>
            submitText="Contact us"
            mainTitle="Contact information"
            className={styles['form-inputs']}
            inputs={initialRequestForm}
            onSendFormRequest={onSendFormRequest}
            loading={submitLoading}
            resetAfterSubmit
            enableCaptcha
          />
        </Modal>
      )}
      {showNotification.state && (
        <Notification
          type={showNotification.type as 'info' | 'error'}
          message={showNotification.message}
        />
      )}
    </div>
  )
}

export default PriceList

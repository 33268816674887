/* eslint-disable react/require-default-props */
import React from 'react'
import styles from '../styles/components/AppSection.module.css'

function AppSection({
  children,
  className,
}: {
  children: JSX.Element[] | JSX.Element | any
  className?: string
}) {
  return (
    <div className={[styles.container, className].join(' ')}>
      <div>{children}</div>
    </div>
  )
}

export default AppSection
